<template>
  <div class="banker-detail">
    <div class="container fluid">
      <div class="card p-1 sm-p-3">
        <h3 class="pt-2 pb-5">Banker Detail</h3>

        <!-- Avatar | Name | Email | Status -->
        <div class="col-12 my-3 d-flex justify-content-center">
          <div>
            <img
              v-if="banker.avatar.length > 0"
              :src="$getFileURL(banker.avatar[0])"
              alt="Avatar"
              class="avatar-img"
            />
            <div v-else class="avatar-placeholder">
              <i class="fas fa-user-circle"></i>
            </div>
          </div>
          <div class="ml-3">
            <p class="name">{{ banker.name }}</p>
            <p class="email">{{ banker.user.email }}</p>
            <div
              class="status mt-1"
              :class="{ active: banker.isActive, inactive: !banker.isActive }"
            >
              {{ banker.isActive ? "Active" : "Banned" }}
            </div>
          </div>
        </div>

        <!-- Personal DATA -->
        <div class="row mb-4">
          <h5 class="col-12 mb-2">Personal Data</h5>
          <display-data
            :class="dataCSS"
            label="Contact Number"
            :content="$getUserPhoneNumber(banker.user)"
          ></display-data>
        </div>

        <!-- BANK DETAILS -->
        <div class="row mb-4">
          <h5 class="col-12 mb-2">Bank Details</h5>
          <display-data
            :class="dataCSS"
            label="Bank"
            :content="banker.bank"
          ></display-data>
          <display-data
            :class="dataCSS"
            label="Branch Name"
            :content="banker.bankBranchName"
          ></display-data>
          <display-data
            :class="dataCSS"
            label="Branch Address 1"
            :content="banker.bankBranchAddress1"
          ></display-data>
          <display-data
            :class="dataCSS"
            label="Branch Address 2"
            :content="banker.bankBranchAddress2"
          ></display-data>
          <display-data
            :class="dataCSS"
            label="Area/Postcode"
            :content="
              banker.bankArea
                ? `${banker.bankArea.name}, ${banker.bankArea.postCode} `
                : 'Not filled'
            "
            :empty="!banker.bankArea"
          ></display-data>
          <display-data
            :class="dataCSS"
            label="City"
            :content="banker.bankCity ? banker.bankCity.name : null"
          ></display-data>
          <display-data
            :class="dataCSS"
            label="State"
            :content="banker.bankState ? banker.bankState.name : null"
          ></display-data>
          <display-data
            :class="dataCSS"
            label="Office Contact Number"
            :content="banker.bankContactNumber"
          ></display-data>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { banker as bankerAPI } from "@/api";
export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      banker: {},
      dataCSS: "col-12 sm-col-6 md-col-4 lg-col-3 px-1 mb-2"
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await bankerAPI.getBanker(this.$route.params.id);
        this.banker = this._.cloneDeep(data);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get banker data. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.banker-detail {
  .status {
    width: fit-content;
    padding: 8px 24px;
    border-radius: 50em;
    color: white;
    background: $color-success;
    &.active {
      background: $color-success;
    }
    &.inactive {
      background: $color-warn;
    }
  }

  .avatar-placeholder {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #ddd;
    text-align: center;
    i {
      font-size: 80px;
      line-height: 1.25;
      color: white;
    }
  }
  .avatar-img {
    @include image(cover);
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .name {
    font-size: 20px;
    font-weight: bold;
    color: #444;
  }

  .email {
    color: #777;
  }
}
</style>
